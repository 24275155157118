import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@/common/api';
import { headerLayout, basicLayout, loginRegisterLayout } from '@/layouts';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import { Row, Col, Button, Avatar, Icon, Dropdown, Menu, Card, Table, List, Divider, Empty, FormModel, Input, Upload, Radio, Select, Tooltip, Message, Drawer, Modal, Tabs, Alert, Spin, DatePicker, ConfigProvider, Cascader, Slider, Popover, Steps, Result ,Collapse, Pagination, Tag} from 'ant-design-vue';

Vue.use(Row).use(Col).use(Button).use(Avatar).use(Icon).use(Dropdown).use(Menu).use(Card).use(Table).use(List).use(Divider).use(Empty).use(FormModel).use(Input).use(Upload).use(Radio).use(Select).use(Tooltip).use(Drawer).use(Tabs).use(Modal).use(Alert).use(Spin).use(DatePicker).use(ConfigProvider).use(Cascader).use(Slider).use(Popover).use(Steps).use(Result).use(Collapse).use(Pagination).use(Tag);

Vue.component('headerLayout', headerLayout);
Vue.component('basicLayout', basicLayout);
Vue.component('loginRegisterLayout', loginRegisterLayout);


Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$api = api;
Vue.prototype.$modal = Modal;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
