import api from '@/common/api';

export default {
	namespaced: true,
	state: {
		category: {}, //全部类别
	},
	mutations: {
		// 设置分类数据
		setCategory: (state, data) => {
			state.category = data
		},
	},
	actions: {
		// 获取全部文章类别
		getCategory({state, commit}, data) {
			return new Promise((resolve, reject) => {
				if(JSON.stringify(state.category) !== '{}') {
					resolve(state.category);
					return false;
				}
				api.manuscriptsCatidGetList().then(res => {
					// 登录成功
					if(res.status) {
						resolve(res.data);
						commit('setCategory', res.data)
					}else{
						reject(res);
					}
				})
			})
		}
	}
}
