<template>
    <div class="login-register-layout">
        <div class="login-top">
            <div class="login-top__logo">
                <img src="~@/assets/images/logo-center.png" class="logo" alt="汉艺唐风">
            </div>
        </div>
        <router-view />
        <div class="login-footer">
            <div class="login-footer__copyright">
                <p>
                    <a href="http://beian.miit.gov.cn" target="_blank" v-if="domain.indexOf('tianxiang.com') >= 0">闽ICP备15024668号-7</a>
                    <a href="http://beian.miit.gov.cn" target="_blank" v-else>闽ICP备18005436号-2</a>
                </p>
                <p>Copyright &copy; @厦门天象文化传播有限公司</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	components: {
	},
    data() {
        return {
            domain: window.location.host
        }
    }
};
</script>

<style lang="less">
.login-register-layout{
    padding: 80px 0 140px;
    box-sizing: border-box;
    height: 100vh;
    min-height: 760px;
    position: relative;
    background: #f0f2f5 url(~@/assets/images/login-bg.svg) no-repeat 50%;
}
// 头部
.login-top{
    text-align: center;
    margin-bottom: 60px;
    &__logo{
        margin-bottom: 10px;
        img{
            width: 280px;
        }
    }
}
// 底部
.login-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 16px;
    margin: 48px 0 24px;
    text-align: center;

    &__links {
        margin-bottom: 8px;
        font-size: 14px;
        a {
            color: rgba(0, 0, 0, 0.45);
            transition: all 0.3s;
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }
    &__copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        p{
            margin-bottom: 5px;
        }
        a{
            color: rgba(0, 0, 0, 0.45);
            &:hover{
                text-decoration: underline;
            }
        }
    }
}
</style>
