<template>
	<div class="header">
        <div class="header-fixed">
            <div class="header-fixed__inner">
                <router-link :to="{name: 'home'}" class="header-logo">
                    <img class="header-logo__img" :src="require('@/assets/images/logo-main.png')" alt="">
                </router-link>
                <div class="header-right">
                    <a-popover class="header-weixin">
                        <template slot="content">
                            <div class="header-weixin__popover">
                                <img :src="require('@/assets/images/weixin.jpg')" alt="">
                                <p>寻求老师帮助</p>
                            </div>
                        </template>
                        <div class="header-weixin__icon"><a-icon type="wechat" /></div>
                    </a-popover>
                    <div class="header-notice" @click="toNotice">
                        <a-icon type="mail" />
                        <span class="header-notice__badge" v-if="unreadCount < 100 && unreadCount > 0">{{unreadCount}}</span>
                        <span class="header-notice__badge" v-else-if="unreadCount >= 100">99+</span>
                    </div>
                    <a-dropdown class="header-user" @visibleChange="dropdownChange">
                        <div class="header-user">
                            <span class="header-user__username">{{username||'--'}}</span>
                            <a-icon class="header-user__icon" :class="dropVisible ? 'header-user__icon--active' : ''" type="caret-down" />
                        </div>
                        <a-menu class="header-user__menu" slot="overlay">
                            <a-menu-item @click="toUser">
                                <a-icon type="tool" />
                                <span href="javascript:;">基本信息</span>
                            </a-menu-item>
                            <a-menu-divider />
                            <a-menu-item @click="toSignOut">
                                <a-icon type="poweroff" />
                                <span href="javascript:;">退出登录</span>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    data() {
        return {
            userAvatar: 'http://cdn.chenrf.com/1X.png',
            dropVisible: false,
        }
    },
    computed: {
		...mapGetters({
            username: 'user/username',
        }),
        ...mapState({
            userInfo: state => state.user.user_info,
            unreadCount: state => state.notice.unreadCount
        })
	},
    watch: {
        userInfo() {
			this.userinfoStatus();
		}
    },
    data() {
        return {
            dropVisible: false,
        }
    },
    mounted() {
        this.userinfoStatus();
    },
    methods: {
        // 用户信息变更,锁定弹窗
        userinfoStatus() {
            let userInfo = this.$store.state.user.user_info;
            if(userInfo.status !== undefined && userInfo.status != 1) {
                this.$modal.error({
                    title: '当前用户被锁定了',
                    content: '请联系平台工作人员处理~',
                    onOk: () =>{
                        this.$store.commit('user/signOut');
                        this.$router.replace({name: 'login'});
                    },
                })
            }
        },
        // 切换
        dropdownChange(visible) {
            this.dropVisible = visible;
        },
        // 跳转通知中心
        toNotice() {
            if(this.$route.name == 'notice') return;
            this.$router.push({name: 'notice'});
        },
        // 退出登录
        toSignOut() {
            this.$modal.confirm({
                content: '确定退出系统',
                onOk: () => {
                    this.$store.commit('user/signOut');
                    this.$router.replace({name: 'login'});
                }
            });
        },
        // 修改密码
        toUser() {
            this.$router.push({name: 'myInfo'});
        }
    }
};
</script>

<style lang="less" scoped>
.header{
    height: 72px;
}
.header-fixed{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0 0 3px 3px #ececec;
    &__inner{
        width: @wrap-width;
        min-width: @min-wrap-width;
        max-width: @max-wrap-width;
        height: 72px;
        margin: 0 auto;
        position: relative;
    }
}
// logo
.header-logo{
    height: 60px;
    line-height: 60px;
    &__img{
        height: 60px;
        margin-top: 5px;
    }
}
// 右侧区域
.header-right{
    position: absolute;
    width: 300px;
    right: 0;
    top: 0;
    text-align: right;
}
// 微信客服
.header-weixin{
    &__icon{
        display: inline-block;
        margin: 0 20px 0 0;
        width: 42px;
        height: 42px;
        line-height: 40px;
        font-size: 20px;
        border: 1px solid #f0f0f0;
        border-radius: 50%;
        text-align: center;
        position: relative;
        cursor: pointer;
        &:hover{
            background-color: #f0f0f0;
        }
    }
    &__popover{
        img{
            width: 160px;
            display: block;
        }
        p{
            text-align: center;
            margin-bottom: 0;
        }
    }
}
// 通知
.header-notice{
    display: inline-block;
    margin: 0 20px 0 0;
    width: 42px;
    height: 42px;
    line-height: 40px;
    font-size: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 50%;
    text-align: center;
    position: relative;
    cursor: pointer;
    &__badge{
        position: absolute;
        right: 0;
        top: -2px;
        transform: translateX(50%);
        background-color: @color-red;
        color: #fff;
        padding: 0 6px;
        border-radius: 10px;
        font-size: 12px;
        height: 20px;
        line-height: 20px;
    }
    &:hover{
        background-color: #f0f0f0;
    }
}
// 用户名
.header-user{
    display: inline-block;
    line-height: 72px;
    font-size: 16px;
    &__username{
        display: inline-block;
        min-width: 80px;
        padding: 0 10px;
    }
    &__icon{
        color: #ccc;
        transition: color .3;
        &--active{
            color: #333;
        }
    }
    &:hover{
        background-color: #f0f0f0;
    }
    &__menu{
        /deep/ .ant-dropdown-menu-item{
            padding: 10px 34px;
        }
    }
}
</style>
