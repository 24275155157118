import { config } from './config';
import md5 from 'md5';
import localStore from 'storejs';
import axios from 'axios';
import { Message, Modal } from 'ant-design-vue';
import router from '@/router';
import qs from 'qs';

const signAlgorithm = obj => {
	let arr = []
	for (var key in obj) {
		if (typeof obj[key] === 'object') {
			arr.push(`${key}=[${signAlgorithm(obj[key])}]`)
		} else {
			arr.push(`${key}=${obj[key]}`)
		}
	}
	if (!(obj instanceof Array)) {
		arr = arr.sort(function (a, b) {
			a = a.slice(0, a.indexOf('='))
			b = b.slice(0, b.indexOf('='))
			return a.localeCompare(b)
		})
	}
	// 转成字符串
	var arrStr = arr.join('')
	// 字符串全部转成小写,加上token
	// toLocaleLowerCase 转换跟php的strtolower转换有差异
	arrStr = arrStr.replace(/[A-Z]/g, c => String.fromCharCode(c.charCodeAt() + 32)) + config.token;
	return md5(arrStr)
}

const HttpRequest = options => {
	let {
		_loadingMessage = false,
		_res2Login = false,
	} = options;
	// 是否需要显示loading
	let messageLoading;
	if(_loadingMessage){
		messageLoading = Message.loading({
			content: _loadingMessage || '加载中...',
			duration: 10000
		});
	}
	options.method = options.method || 'GET';
	options.data = options.data || {};
	let requestData = options.data
	requestData.app_id = config.app_id;
	requestData.request_time = Math.round(new Date().getTime() / 1000);
	requestData.access_token = localStore('access_token') || '';
	requestData.sign2 = signAlgorithm(requestData);
	let axiosConf = {
		url: options.url,
		method: options.method,
	}
	// 提交方式参数差异
	if(options.method == 'GET') {
		axiosConf.params = requestData;
	}else{
		axiosConf.data = qs.stringify(requestData);
	}
	return new Promise(function(resolve, reject){
		axios.request(axiosConf).then((res) => {
			options._loadingMessage && messageLoading;
			// 判断是否有自动跳转登录
			if(_res2Login && res.data.status === 0 && [20000, 20001, 20002].indexOf(res.data.error_code)>-1) {
				localStore.set('access_token','');
				Modal.destroyAll();
				Modal.warning({
					okText: '去登录',
					content: '登录信息过期，请重新登录',
					onOk: () => {
						router.push({name: 'login'});
					}
				})
				return;
			}
			resolve(res.data)
		}).catch(function (error) {
			reject(error)
		});
	}).catch((e) => {})
}

export default HttpRequest

//系统状态码
// 10000: '参数 sign 不能为空',
// 10001: '参数 sign 错误',
// 10002: '接口请求超时，请重新发起请求',
// 10003: '安全检查失败',
// 10004: '该版本的接口不存在',
// 10005: '参数 app_id 不为空',
// 10006: '你没有该接口请求权限',
// 10007: '程序出现未知错误',
// 10008: '接口请求错误',
// 10009: '接口请求方式不正确',
// 10010: '该应用不存在',
// // 会员通行证
// 20000: '您还没有登录',
// 20001: '获取不到 access_token 参数',
// 20002: '参数 access_token 已过期',
// 20003: '账号密码错误',
// 20004: '会员账号不存在',
// 20005: '该OpenId已经绑定其他账号',
// 20006: '系统参数错误',
// // 支付相关
// 30000: '基础参数验证失败',
