import api from '@/common/api';
import localStore from 'storejs';

export default {
	namespaced: true,
	state: {
		access_token: '',
		user_info: {
			extend: {},
			uinfo: {}
		},
		// 用户选择类别
		category: {}
	},
	getters: {
		// 是否登录
		isLogin: () => {
			return !!localStore('access_token')
		},
		// 用户名
		username: state => {
			if(!state.user_info.id) return '';
			let mobile = state.user_info.mobile + '';
			mobile = mobile.substr(0, 3) + '****' + mobile.substr(7);
			return state.user_info.nickname || mobile;
		},
		// 是否首次登陆
		isFirst: state => {
			return state.user_info.extend && state.user_info.extend.newbie_guide == 0;
		},
		isNewPeople: state => {
			return state.user_info.grade == 1;
		}
	},
	mutations: {
		// 设置token
		setToken: (state, token) => {
			localStore.set('access_token', token);
			state.access_token = token;
		},
		// 设置用户信息
		setUserinfo: (state, info) => {
			//info.is_inside = 1;
			state.user_info = info
		},
		// 退出
		signOut: (state) => {
			localStore.remove('access_token');
			state.access_token = '';
			state.user_info = {uinfo: {}};
		},
		// 设置用户选择的分类
		setCategory: (state, data) => {
			state.category = data
		},
	},
	actions: {
		// 获取用户信息
		getUserinfo({commit}, data) {
			return new Promise((resolve, reject) => {
				if(localStore('access_token') == '') {
					reject({
						status: 0,
						msg: '请重新登录'
					});
				}
				api.userInfo(data).then(res => {
					// 登录成功
					if(res.status) {
						commit('setUserinfo', res.data);
						resolve(res.data);
					}else{
						// reject(res);
					}
				})
			}).catch((e) => {})
		},
		// 获取用户课程分类
		getCategory({state, commit}, data) {
			return new Promise((resolve, reject) => {
				if(JSON.stringify(state.category) !== '{}') {
					resolve(state.category);
					return false;
				}
				api.userManuscriptsCatidSelected().then(res => {
					// 登录成功
					if(res.status) {
						resolve(res.data);
						commit('setCategory', res.data)
					}else{
						reject(res);
					}
				})
			})
		},
		// 更新用户基本信息
		updataInfo({dispatch}, data) {
			return new Promise((resolve, reject) => {
				api.userEdit(data).then(res => {
					// 更新成功
					if(res.status) {
						dispatch('getUserinfo');
						resolve(res);
					}else{
						reject(res);
					}
				})
			})
		},
		// 更新用户认证信息
		updataAuthInfo({dispatch}, data) {
			return new Promise((resolve, reject) => {
				api.updataAuthInfo(data).then(res => {
					// 更新成功
					if(res.status) {
						dispatch('getUserinfo');
						resolve(res);
					}else{
						reject(res);
					}
				})
			})
		},
		// 更新用户密码
		updataPassword({commit, dispatch}, data) {
			return new Promise((resolve, reject) => {
				api.userPassword(data).then(res => {
					// 更新成功
					if(res.status) {
						resolve(res);
						commit('signOut');
					}else{
						reject(res);
					}
				})
			})
		},
	}
}
