import HttpRequest from "@/common/http";
import { config } from "@/common/config";

const apiOption = {
    /**
     * 图片文件上传
     * especially		*卷，默认d1xz
     * size		*文件大小
     * exts		*扩展名
     * -		文件
     */
    imageUpload: {
        url: `${config.toolapi2Host}/upload/v1/image/upload.html`,
        method: "POST",
    },
    /**
     * 图片文件上传base64
     * base64			*base64文本
     * especially		*上传的Bucket名
     * suffix			*文件后缀名只支持png、jpg、gif
     */
    imageBase64: {
        url: `${config.toolapi2Host}/upload/v1/image/base64.html`,
        method: "POST",
    },
    /**
     * 获取站内消息
     * limit		条数,默认20
     * page			分页
     */
    noticeGetlist: {
        url: `${config.toolapi2Host}/creation/v1/member.notice/getlist.html`,
    },
    /**
     * 上报站内消息已读
     * id			*消息ID
     */
    noticeIsread: {
        url: `${config.toolapi2Host}/creation/v1/member.notice/isRead.html`,
    },
    /**
     * 会员登录验证短信发送
     * phone		*手机号
     */
    sendVerificationCode: {
        url: `${config.toolapi2Host}/creation/v1/member.login/sendVerificationCode.html`,
    },
    /**
     * 会员登录验证短信发送
     * mobile			*手机号
     * captcha			*验证码
     * openidsecret		OpenID密文(绑定时候)
     */
    mobilelogin: {
        url: `${config.toolapi2Host}/creation/v1/member.login/mobilelogin.html`,
    },
    /**
     * 二维码登录
     * openidsecret		*OpenID密文
     */
    loginQrcode: {
        url: `${config.toolapi2Host}/creation/v1/member.login/qrcode.html`,
        method: "POST",
    },
    /**
     * 通过二维码ticket换取openid密文
     * ticket			*二维码ticket
     */
    openidSecret: {
        url: `${config.toolapi2Host}/creation/v1/member.login/openidSecret.html`,
        method: "POST",
    },
    /**
     * 获取公众号登录二维码
     */
    creationLoginQrcode: {
        url: `https://payment.txsdk.com/access/wx1cb51a2b4582fe80/creationLoginQrcode.html`,
    },
    /**
     * 通过二维码ticket换取openid密文，轮询
     * ticket	*二维码ticket
     */
    openidSecret: {
        url: `${config.toolapi2Host}/creation/v1/member.login/openidSecret.html`,
    },
    /**
     * 二维码登录
     * openidsecret		*openid密文
     */
    loginQrcode: {
        url: `${config.toolapi2Host}/creation/v1/member.login/qrcode.html`,
    },
    /**
     * 用户信息
     * access_token		*用户标识
     */
    userInfo: {
        url: `${config.toolapi2Host}/creation/v1/member.user/info.html`,
    },
    /**
     * 置顶数据
     * access_token		*用户标识
     */
    indexTop: {
        url: `${config.toolapi2Host}/creation/v1/member.index/top.html`,
    },
    /**
     * 获取任务列表
     * access_token		*用户标识
     */
    taskCenter: {
        url: `${config.toolapi2Host}/creation/v1/member.task/center.html`,
    },
    /**
     * 领取任务
     * iden		*任务标识
     */
    taskTake: {
        url: `${config.toolapi2Host}/creation/v1/member.task/take.html`,
    },
    /**
     * 取消任务
     * id		*任务主键ID
     */
     taskDelete: {
        url: `${config.toolapi2Host}/creation/v1/member.task/delete.html`,
    },
    /**
     * 获取持之以恒任务数据
     * access_token		*用户标识
     */
    perserveCompletion: {
        url: `${config.toolapi2Host}/creation/v1/member.perserve/completion.html`,
    },
    /**
     * 获取稿件分类
     */
    manuscriptsCatidGetList: {
        url: `${config.toolapi2Host}/creation/v1/member.manuscripts_catid/getlist.html`,
    },
    /**
     * 获取用户已选稿件分类
     */
    userManuscriptsCatidSelected: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts_catid/selected.html`,
    },
    /**
     * 获取用户已选稿件分类
     * catids[]     分类ID
     */
    userManuscriptsCatidUpdate: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts_catid/update.html`,
        method: "POST",
    },
    /**
     * 获取稿件任务
     * keyword		关键词搜索
     * page         分页
     * limit        条数
     * catid        类别
     */
    manuscriptsCenter: {
        url: `${config.toolapi2Host}/creation/v1/member.manuscripts/center.html`,
    },
    /**
     * 获取稿件任务
     * ids		试稿ID
     */
    userManuscriptsTest: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts/test.html`,
    },
    /**
     * 接稿
     * ids		*稿件ID
     */
    manuscriptsJiegao: {
        url: `${config.toolapi2Host}/creation/v1/member.manuscripts/jiegao.html`,
    },
    /**
     * 我的稿件
     * page         分页
     * limit        条数
     */
    userManuscriptsGetlist: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts/getlist.html`,
    },
    /**
     * 稿件退回
     * id		*稿件ID
     */
    userManuscriptsBack: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts/back.html`,
    },
    /**
     * 稿件详情
     * id		*稿件ID
     */
    userManuscriptsInfo: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts/info.html`,
    },
    /**
     * 稿件投稿
     * id		*稿件ID
     */
    userManuscriptsTougao: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts/tougao.html`,
        method: 'POST'
    },
    /**
     * 自由投稿
     * catid		*稿件分类ID
     * title        *稿件标题
     * content      *稿件正文
     */
    userManuscriptsFreeTougao: {
        url: `${config.toolapi2Host}/creation/v1/member.user_manuscripts/freeTougao.html`,
        method: 'POST'
    },


    /**
     * 收入记录
     * page         分页
     * limit        条数
     */
    fundrecordGetlist: {
        url: `${config.toolapi2Host}/creation/v1/member.fundrecord/getlist.html`,
    },
    /**
     * 提现相关-置顶数据
     */
    withdrawTop: {
        url: `${config.toolapi2Host}/creation/v1/member.withdraw/top.html`,
    },
    /**
     * 提现相关-获取结算记录列表
     */
    withdrawGetlist: {
        url: `${config.toolapi2Host}/creation/v1/member.withdraw/getlist.html`,
    },
    /**
     * 提现相关-申请提现
     */
    withdrawShenqing: {
        url: `${config.toolapi2Host}/creation/v1/member.withdraw/shenqing.html`,
    },
    /**
     * 用户修改
     */
    userEdit: {
        url: `${config.toolapi2Host}/creation/v1/member.user/edit.html`,
        method: 'POST'
    },
    /**
     * 用户同意协议
     */
    authContract: {
        url: `${config.toolapi2Host}/creation/v1/member.auth/contract.html`
    },
    /**
     * 更新用户认证资料
     * extend[username]                 *姓名
     * extend[idcard]                   *身份证号
     * extend[idcard_img_reverse]       *身份证反面
     * extend[idcard_img_front]         *身份证正面
     */
    updateAuthInfo: {
        url: `${config.toolapi2Host}/creation/v1/member.auth/updateAuthInfo.html`,
        method: 'POST'
    },
    /**
     * 更新用户认证资料
     * extend[username]         *姓名
     * extend[bank]             *银行
     * extend[bank_area]        *开户行
     * extend[bank_cart]        *卡号
     */
    updateShoukuan: {
        url: `${config.toolapi2Host}/creation/v1/member.user/updateShoukuan.html`,
        method: 'POST'
    },
    /**
     * 文章列表
     * page         分页
     * limit        条数
     */
    articleGetlist: {
        url: `${config.toolapi2Host}/creation/v1/member.article/getlist.html`,
    },
    /**
     * 文章详情
     * id        文章ID
     */
    articleDetail: {
        url: `${config.toolapi2Host}/creation/v1/member.article/detail.html`,
    },
    /**
     * 文章阅读上报
     * id        文章ID
     */
    articleAddviews: {
        url: `${config.toolapi2Host}/creation/v1/member.article/addviews.html`,
    },
};
let exportApi = {};
// 封装默认出口对象
for (let k in apiOption) {
    exportApi[k] = (obj = {}) => {
        let config = {};
        if (obj._loadingMessage) {
            config._loadingMessage = obj._loadingMessage;
            delete obj._loadingMessage;
        }
        if (obj._res2Login) {
            config._res2Login = obj._res2Login;
            delete obj._res2Login;
        }
        config.method = apiOption[k].method || "GET";
        config.data = obj;
        return HttpRequest({
            url: apiOption[k].url,
            ...config,
        });
    };
}
export default exportApi;
