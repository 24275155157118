import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import notice from './modules/notice';
import mart from './modules/mart';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // 临时上传文件
        snapUploadFile: false
    },
    mutations: {
        setSnapUploadFile: (state, fileData) => {
			state.snapUploadFile = fileData;
		},
        removeSnapUploadFile: (state) => {
			state.snapUploadFile = false;
		},
    },
    actions: {
    },
    modules: {
        user,
        notice,
        mart
    }
})
