<template>
    <div class="page-nav" :class="{'page-nav--scroll': scroll}" ref="nav">
        <a-menu mode="inline" :open-keys="openKeys" @select="selectChange" :selected-keys="selectedKeys">
            <a-menu-item key="home"><a-icon type="home" />首页</a-menu-item>
            <a-sub-menu key="write">
                <span slot="title"><a-icon type="book" /><span>创作中心</span></span>
                <a-menu-item key="writeMart">接稿中心</a-menu-item>
                <a-menu-item key="writeArticle">我的文章</a-menu-item>
                <a-menu-item key="writeFree" v-if="userInfo.is_inside == 1">自由投稿</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="finance" v-if="userInfo.is_inside != 1">
                <span slot="title"><a-icon type="money-collect" /><span>结算中心</span></span>
                <a-menu-item key="financeIncome">收入记录</a-menu-item>
                <a-menu-item key="financePayment">财务结算</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="my">
                <span slot="title"><a-icon type="user" /><span>个人中心</span></span>
                <a-menu-item key="myInfo">基本信息</a-menu-item>
                <a-menu-item key="myCollect" v-if="userInfo.is_inside != 1">收款信息</a-menu-item>
                <a-menu-item key="myCategory">类别选择</a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="help">
                <span slot="title"><a-icon type="question-circle" /><span>帮助/服务</span></span>
                <a-menu-item key="helpList">教学&帮助</a-menu-item>
            </a-sub-menu>
        </a-menu>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState({
            userInfo: state => state.user.user_info
        })
	},
	data() {
		return {
			openKeys: ['write', 'finance', 'my', 'help'],
            selectedKeys: [],
            scroll: false
		};
	},
    watch: {
        '$route' (to) {
            let routeName = to.name;
            routeName = Array.isArray(routeName) ? routeName[0] : routeName;
            this.selectedKeys.splice(0, 1, routeName);
        }
    },
    mounted() {
        let routeName = this.$route.name;
        routeName = Array.isArray(routeName) ? routeName[0] : routeName
        this.selectedKeys.splice(0, 1, routeName);
        // 判断是否大于屏幕高度
        this.$nextTick(() => {
            if(this.$refs.nav.offsetHeight > document.body.offsetHeight - 87) {
                this.scroll = true;
            }
        })
    },
	methods: {
        // 点击跳转
        selectChange(select) {
            this.$router.push({
                name: select.key
            }).catch(err => {console.warn(err)})
            
        }
	},
};
</script>
<style lang="less" scoped>
.page-nav{
    width: 200px;
    position: fixed;
    top: 87px;
    padding-bottom: 20px;
    max-height: calc(100% - 100px);
    overflow: hidden;
    background-color: #fff;
    &:hover{
        overflow-y: scroll;
    }
    &:hover::-webkit-scrollbar{
        display: block;
    }
    &::-webkit-scrollbar{
        display: none;
        width: 6px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #dfe1e6;
        border-radius: 3px;
        opacity: .6;
    }
    &::-webkit-scrollbar-track{
        background-color: #fff;
        border-radius: 4px;
    }
    /deep/ .ant-menu{
        width: 200px;
    }
    /deep/ .ant-menu-submenu-arrow{
        display: none;
    }
    /deep/ .ant-menu-submenu{
        padding-top: 4px
    }
    /deep/ .ant-menu-item, 
    /deep/ .ant-menu-submenu-title{
        height: 35px;
        line-height: 35px;
        font-size: 15px;
    }
    /deep/ .ant-menu-submenu-title,
    /deep/ .ant-menu-submenu-title:hover {
        color: #999;
        cursor: default
    }
    /deep/ .ant-menu-inline{
        border-right: 0;
    }
    /deep/ .ant-menu-inline .ant-menu-item::after{
        right: auto;
        left: 0;
    }
}
</style>