import localStore from 'storejs';
// 默认配置
let confJson = {
	app_id: 66,
	token: "1lJGEZhMqeAGnnfW4f7Kr2HF5ePv3ht4",
	toolapi2Host: 'https://toolapi2.txsdk.com'
}

let thisHost = window.location && window.location.host;
// 开发环境dev.xxx
if((thisHost && thisHost.indexOf('dev.') == 0) || thisHost.indexOf('localhost') >=0 || localStore('develop') == 'dev'){
	confJson.app_id = 2,
	confJson.token = 'asdljklxjc12897SDKJXDsd046564xhd';
	confJson.toolapi2Host = 'http://dev.toolapi2.txsdk.com'
}
// 测试环境xxx.dev.xxx
if((thisHost && (thisHost.indexOf('dev.') > 1)) || localStore('develop') == 'test'){
	confJson.app_id = 2,
	confJson.token = 'asdljklxjc12897SDKJXDsd046564xhd';
	confJson.toolapi2Host = 'http://toolapi2.dev.txsdk.com'
}
// 正式测试机
if(localStore('develop') == 'testing'){
	confJson.toolapi2Host = 'http://toolapi2.txsdk.com';
}
// 正式
if(localStore('develop') == 'production'){
	confJson.app_id = 66;
	confJson.token = "1lJGEZhMqeAGnnfW4f7Kr2HF5ePv3ht4",
	confJson.toolapi2Host = 'https://toolapi2.txsdk.com';
}

export const config = confJson;


