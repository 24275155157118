import api from '@/common/api';

export default {
	namespaced: true,
	state: {
		list: {}, // 列表集合
		limit: 10, //页数条数
		count: 0, // 总条数
		unreadCount: 0, // 未读条数
	},
	mutations: {
		// 设置token
		setListData: (state, resData) => {
			state.list['page_'+ resData.page] = resData.list;
			state.count = resData.count;
			state.unreadCount = resData.unread;
		},
		updataIsread: (state, resData) => {
			let thisPageList = state.list['page_'+ resData.page];
			state.unreadCount = state.unreadCount - 1;
			for(let i = 0, max = thisPageList.length; i < max; i++){
				if(thisPageList[i].id == resData.id) {
					thisPageList[i].is_read = 1;
					return;
				}
			}
		}
	},
	actions: {
		// 获取列表
		getList({state, commit}, data) {
			let option = Object.assign({
				limit: state.limit,
				page: 1
			}, data);
			return new Promise((resolve, reject) => {
				if(!state.list['page_'+ option.page]) {
					api.noticeGetlist(option).then(res => {
						if(res.status) {
							resolve(res.data.list);
							commit('setListData', {
								list: res.data.list, 
								page: option.page,
								count: res.data.count,
								unread: res.data.no_read_count
							})
						}else{
							reject(res);
						}
					})
				}else{
					resolve(state.list['page_'+ option.page]);
				}
			})
		},
		// 上报已读
		reportRead({commit}, option) {
			return new Promise((resolve, reject) => {
				api.noticeIsread({id: option.id}).then(res => {
					if(res.status) {
						resolve();
						commit('updataIsread', {
							id: option.id,
							page: option.page
						})
					}else{
						reject(res);
					}
				})
			})
		}
	}
}
