<template>
    <div class="page-wrap">
        <page-header />
        <div class="page-wrap__main">
            <page-nav />
            <div class="page-wrap__container">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageNav from "@/components/pageNav";
export default {
	components: {
		pageHeader,
		pageNav,
	},
};
</script>

<style lang="less">
.page-wrap {
	min-height: 100vh;
    &__main{
        margin: 15px auto 20px;
	    width: @wrap-width;
        min-width: @min-wrap-width;
        max-width: @max-wrap-width;
	    position: relative;
    }
    &__container{
        margin-left: 220px;
    }
}
</style>
