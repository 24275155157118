import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        meta: {
            layout: 'loginRegisterLayout',
            title: '登录' 
        },
        component: () => import('@/views/login.vue')
    }, {
        path: '/home.html',
        name: 'home',
        meta: {
            layout: 'basicLayout',
            title: '课程列表' 
        },
        component: () => import('@/views/home.vue')
    }, {
        path: '/notice.html',
        name: 'notice',
        meta: {
            layout: 'basicLayout',
            title: '通知中心' 
        },
        component: () => import('@/views/notice.vue')
    }, {
        path: '/write/mart.html',
        name: 'writeMart',
        meta: {
            layout: 'basicLayout',
            title: '投稿中心' 
        },
        component: () => import('@/views/write/mart.vue')
    }, {
        path: '/write/article.html',
        name: 'writeArticle',
        meta: {
            layout: 'basicLayout',
            title: '我的文章' 
        },
        component: () => import('@/views/write/article.vue')
    }, {
        path: '/works/article/edit/:id.html',
        name: 'writeEdit',
        meta: {
            layout: 'basicLayout',
            title: '文章编辑' 
        },
        component: () => import('@/views/write/edit.vue')
    }, {
        path: '/works/article/free.html',
        name: 'writeFree',
        meta: {
            layout: 'basicLayout',
            title: '自由编辑' 
        },
        component: () => import('@/views/write/edit.vue')
    }, {
        path: '/finance/income.html',
        name: 'financeIncome',
        meta: {
            layout: 'basicLayout',
            title: '收入记录' 
        },
        component: () => import('@/views/finance/income.vue')
    }, {
        path: '/finance/payment.html',
        name: 'financePayment',
        meta: {
            layout: 'basicLayout',
            title: '财务结算' 
        },
        component: () => import('@/views/finance/payment.vue')
    },{
        path: '/my/info.html',
        name: 'myInfo',
        meta: {
            layout: 'basicLayout',
            title: '基本信息' 
        },
        component: () => import('@/views/my/info.vue')
    }, {
        path: '/my/auth.html',
        name: 'myAuth',
        meta: {
            layout: 'basicLayout',
            title: '认证信息' 
        },
        component: () => import('@/views/my/auth.vue')
    }, {
        path: '/my/collect.html',
        name: 'myCollect',
        meta: {
            layout: 'basicLayout',
            title: '收款信息' 
        },
        component: () => import('@/views/my/collect.vue')
    }, {
        path: '/my/category.html',
        name: 'myCategory',
        meta: {
            layout: 'basicLayout',
            title: '类别选择' 
        },
        component: () => import('@/views/my/category.vue')
    }, {
        path: '/help/list.html',
        name: 'helpList',
        meta: {
            layout: 'basicLayout',
            title: '教学帮助' 
        },
        component: () => import('@/views/help/list.vue')
    }, {
        path: '/help/article/:id.html',
        name: 'helpArticle',
        meta: {
            layout: 'basicLayout',
            title: '文章详情' 
        },
        component: () => import('@/views/help/article.vue')
    }, {
		path: "*",
		name: "error",
		component: () => import("@/views/404.vue")
    }
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})
// 路由守卫
router.beforeEach((to, from, next)=>{
	//路由统一'/'配置
	if(!/\/$/.test(to.path) && !/(.asp|.html|.php|.aspx)$/.test(to.path)) {
		next({path: `${to.path}/`})
	}
    if(['login'].indexOf(to.name) < 0) {
        store.dispatch('user/getUserinfo', {_res2Login: true});
    }
    next();
})
export default router
